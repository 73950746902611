<template>
  <div id="cotationTable">
      <b-field label="Fond">
          <b-select v-model="filteredFund">
              <option :value="null">Tous</option>
              <option v-for='(f, $f) in funds' :key='$f' :value="f.isin">{{f.label}}</option>
          </b-select>
      </b-field>
      <b-table :data='filteredData'>
        <b-table-column label="Fond" field="label" v-slot="props" sortable>{{props.row.label}}</b-table-column>
        <b-table-column label="ISIN" field="isin" v-slot="props" sortable>{{props.row.isin}}</b-table-column>
        <b-table-column label="Date" field="date" v-slot="props" sortable>{{new Date(props.row.date).toLocaleDateString()}}</b-table-column>
        <b-table-column label="Valeur" field="valeur" v-slot="props" numeric sortable>{{props.row.valeur}}</b-table-column>
        <b-table-column label="Indice" field="indice" v-slot="props" numeric sortable>{{props.row.indice}}</b-table-column>
      </b-table>
  </div>
</template>

<script>
export default {
    name: 'CotationTable',
    props: ['data', 'funds'],
    data: () => ({
        filteredFund: null,
        from: null,
        to: null
    }),
    computed: {
        filteredData() {
            if (this.filteredFund == null) return this.data
            return this.data.filter(f => f.isin == this.filteredFund)
        }
    }
}
</script>

<style>

</style>