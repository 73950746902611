<template>
  <div id="dashboard" class="container">
    <div class="is-pulled-right">
      <b-button type="is-primary" @click='handleAdd' icon-left='plus-circle'>Ajouter</b-button>
    </div>
    <h1 class="title">Gestion des cotations TurgotAM</h1>
    <cotation-table :funds='funds' :data='history'></cotation-table>
    <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>

    <b-modal :active.sync='showModal'>
      <div class="modal-card" style="width: auto; min-height: 85vh;">
          <header class="modal-card-head">
              <p class="modal-card-title">Nouvelle entrée</p>
          </header>
          <section class="modal-card-body">
              <form ref='newEntryForm'>
                <b-field label="Date">
                    <b-datepicker v-model="newDate" locale='fr-FR' trap-focus required @input='handleDateChanged'></b-datepicker>
                </b-field>
                <table class="table is-fullwidth is-narrow">
                  <thead>
                    <tr>
                      <th>Fonds</th>
                      <th>Valeur</th>
                      <th>Indice</th>
                      <th>Date</th>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody v-if='entries != null'>
                    <tr v-for='f in creatingFunds' :key='f.id'>
                      <th>{{f.label}}</th>
                      <td>
                        <b-input size="is-small" v-model="entries[f.id].valeur" type="number" min="0" step="0.01"></b-input>
                      </td>
                      <td>
                        <b-input size="is-small" v-model="entries[f.id].indice" type="number" min="0" step="0.01"></b-input>
                      </td>
                      <td>
                        <b-input size="is-small" v-model="entries[f.id].date" type="date"></b-input>
                      </td>
                      <td>
                        <a class="delete" @click='dropEntry(f.id)'></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
          </section>
          <footer class="modal-card-foot">
              <button class="button" type="button" @click="showModal = false">Annuler</button>
              <b-button type="is-primary" icon-left="save" @click="doSave">Enregistrer</b-button>
          </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CotationTable from './components/CotationTable'
import axios from 'axios'
import moment from 'moment'
import pjs from '../package.json'
const { API_ENDPOINT } = pjs.config
export default {
  name: 'Dashboard',
  components: {
    CotationTable
  },
  props: ['token'],
  data: () => ({
    entries: null,
    showModal: false,
    loading: true,
    limit: 500,
    page: 1,
    funds: [],
    history: [],
    newDate: new Date(),
    selectedFunds: []
  }),
  computed: {
    orderedFunds() {
      let funds = this.funds
      return funds.sort((a, b) => {
        if (a.label > b.label) return 1
        return -1
      })
    },
    creatingFunds() {
      return this.funds.filter((f) => this.entries[f.id] !== undefined)
    }
  },
  methods: {
    async fetchData() {
      const res = await axios.get(`${API_ENDPOINT}/cotation/all?limit=${this.limit}&page=${this.page}`)
      this.loading = false
      this.history = res.data
      return res.data
    },
    async getFunds() {
      const res = await axios.get(`${API_ENDPOINT}/cotation/funds`)
      this.funds = res.data
      return res.data
    },
    handleAdd() {
      this.showModal = true
    },
    async doSave() {
      this.loading = true
      try {
        await axios.post(`${API_ENDPOINT}/cotation/create/batch`, this.entries)
        this.$buefy.toast.open({
          message: 'Enregistrement réalisé avec succès',
          type: 'is-success'
        })
        this.fetchData()
        this.showModal = false
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Une erreur est survenue',
          type: 'is-danger'
        })
      }
      
      this.loading = false
    },
    buildEntries() {
      this.entries = {}
      for (const f of this.orderedFunds) {
        this.entries[f.id] = {
          valeur: null,
          indice: null,
          date: moment(this.newDate).format('Y-M-DD')
        }
      }
    },
    handleDateChanged() {
      this.buildEntries()
    },
    dropEntry(id) {
      const entries = {...this.entries}
      delete entries[id]
      this.entries = entries
    }
  },
  mounted() {
    console.log('Api endpoint', API_ENDPOINT)
    axios.defaults.headers['Authorization'] = `Bearer ${this.token}`
    this.fetchData()
    this.getFunds().then(() => {
      this.buildEntries()
    })
  }
}
</script>

<style>

#dashboard {
  margin-top: 40px;
}
</style>
