<template>
  <div id="app" class="container">
    <login v-if='!authenticated' @login='login'></login>
    <dashboard v-else :token='token'></dashboard>
  </div>
</template>

<script>
import Dashboard from './Dashboard'
import Login from './Login'
import axios from 'axios'

import pjs from '../package.json'
const { API_ENDPOINT } = pjs.config
export default {
  name: 'App',
  components: {
    Dashboard,
    Login,
  },
  data: () => ({
    component: null,
    token: window.sessionStorage.getItem('token'),
    user: null,
    authenticated: false
  }),
  methods: {
    async checkAuth() {
      return this.token !== null
    },
    login({identifier, password}) {
      axios.post(API_ENDPOINT + '/auth/local', {
        identifier,
        password,
      })
      .then((response) => {
        this.authenticated = true
        this.user = response.data.user
        this.token = response.data.jwt
        window.sessionStorage.setItem('token', this.token)
        this.$buefy.toast.open({
          message: `Bienvenue !`,
          type: 'is-success'
        })
      })
      .catch((error) => {
        console.log(error)
        this.authenticated = false
        this.$buefy.toast.open({
          message: 'Identifiants incorrects',
          type: 'is-danger'
        })
      })
    }
  },
  mounted() {
    this.checkAuth().then((authenticated) => {
      this.authenticated = authenticated
    })
  }
}
</script>

<style>

#app {
  margin-top: 40px;
}
</style>
