<template>
  <div id="login">
      <div id="loginForm" class="card">
          <div class="card-content">
                <h2 class="subtitle">Connexion</h2>
                <b-field label="Email">
                    <b-input type="email" v-model="email"></b-input>
                </b-field>
                <b-field label="Mot de passe">
                    <b-input type="password" v-model="password"></b-input>
                </b-field>
                <b-button type="is-primary" icon-left="user" @click='login'>Se connecter</b-button>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "Login",
    data: () => ({
        email: '',
        password: ''
    }),
    methods: {
        login() {
            this.$emit('login', { identifier: this.email, password: this.password })
        }
    }
}
</script>

<style>
#login {
    display: flex;
    justify-content: center;
    align-items: center;
}
#loginForm {
    width: 450px;
}
</style>